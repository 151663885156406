import * as React from "react";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "@/app/lib/utils";
import { Fragment, useContext, useState, useEffect } from "react";

// Custom Dialog component that extends HeadlessDialog with additional props
interface DialogProps extends React.ComponentProps<typeof HeadlessDialog> {
  preventCloseOnOutsideClick?: boolean;
}

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  ({ preventCloseOnOutsideClick, ...props }, ref) => {
    // If preventCloseOnOutsideClick is true, we'll use a custom onClose handler
    // that prevents closing when clicking outside
    const handleClose = (open: boolean) => {
      if (preventCloseOnOutsideClick) {
        // Only allow closing via explicit UI actions (like pressing Escape or clicking a close button)
        // This prevents closing when clicking outside the dialog
        return;
      }
      
      // Otherwise, use the original onClose handler
      if (props.onClose) {
        props.onClose(open);
      }
    };

    return <HeadlessDialog {...props} onClose={handleClose} />;
  }
);

// We'll create our own DialogTrigger since Headless UI doesn't provide one
const DialogTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => (
  <button type="button" ref={ref} className={cn("", className)} {...props} />
));
DialogTrigger.displayName = "DialogTrigger";

const DialogContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{
    className?: string;
    onCloseButton?: () => void;
  }>
>(({ className, children, onCloseButton, ...props }, ref) => (
  <>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-[60]" />
    </Transition.Child>

    <div className="fixed inset-0 overflow-y-auto z-[60]">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <HeadlessDialog.Panel
            ref={ref}
            className={cn(
              "w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all relative",
              className
            )}
            {...props}
          >
            {children}
            {onCloseButton && (
              <button
                className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none"
                type="button"
                aria-label="Close"
                onClick={onCloseButton}
              >
                <XMarkIcon className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </button>
            )}
          </HeadlessDialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </>
));
DialogContent.displayName = "DialogContent";

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <HeadlessDialog.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = "DialogTitle";

const DialogDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <HeadlessDialog.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
DialogDescription.displayName = "DialogDescription";

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  type DialogProps,
};
