import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CompanyUser, UserRoleLevels } from "../../../interfaces";

// Base selector
const selectCompanyUsersState = (state: RootState) => state.companyUsers;

// Select all company users as an object
export const selectCompanyUsersItems = createSelector(
  selectCompanyUsersState,
  (state) => state.items,
);

// Select all company users as an array
export const selectCompanyUsersArray = createSelector(
  selectCompanyUsersItems,
  (items) => Object.values(items),
);

export const selectCompanyUsersWithRoles = (roles: UserRoleLevels[]) =>
  createSelector(selectCompanyUsersArray, (users) =>
    users.filter((user) => roles.includes(user.role)),
  );

// Select loading state
export const selectCompanyUsersLoading = createSelector(
  selectCompanyUsersState,
  (state) => state.isLoading,
);

// Select error state
export const selectCompanyUsersError = createSelector(
  selectCompanyUsersState,
  (state) => state.error,
);

// Select a single company user by ID
export const selectCompanyUserById = (userId: string) =>
  createSelector(selectCompanyUsersItems, (items) => items[userId] || null);
