import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Base selector
const selectOrdersState = (state: RootState) => state.orders;

export const selectAllOrders = createSelector(
    selectOrdersState,
    (state) => state.items
);

export const selectAllOrdersArray = createSelector(
    selectAllOrders,
    (items) => Object.values(items).flat()
);

export const selectProjectOrders = (projectId: string) => createSelector(
    selectAllOrders,
    (items) => items[projectId] || []
);

export const selectOrdersLoading = (projectId: string) => createSelector(
    selectOrdersState,
    (state) => state.isLoading[projectId] || false
);

export const selectOrderById = (projectId: string, orderId: string) => createSelector(
    selectProjectOrders(projectId),
    (orders) => orders.find((order) => order.id === orderId) || null
);

export const selectOrderName = (orderId: string) => createSelector(
    selectAllOrdersArray,
    (orders) => orders.find((order) => order.id === orderId)?.name || null
);
