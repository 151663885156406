import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";

export async function uploadFile(path: string, file: File): Promise<string> {
  try {
    console.log("[Storage] Starting file upload to path:", path);
    console.log("[Storage] File details:", {
      name: file.name,
      type: file.type,
      size: file.size,
    });

    const storageRef = ref(storage, path);

    console.log("[Storage] Created storage reference, uploading file...");
    const snapshot = await uploadBytes(storageRef, file);
    console.log(
      "[Storage] File uploaded successfully, getting download URL...",
    );

    const url = await getDownloadURL(snapshot.ref);
    console.log("[Storage] Got download URL:", url);

    return url;
  } catch (error: any) {
    console.error("[Storage] Error uploading file:", error);
    console.error("[Storage] Error details:", {
      code: error.code,
      message: error.message,
      serverResponse: error.serverResponse,
    });
    throw error;
  }
}

export async function uploadPdfDocument(
  companyId: string,
  projectId: string,
  file: File,
): Promise<string> {
  if (!file.type.match("application/pdf")) {
    throw new Error("File must be a PDF");
  }

  const uniqueId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  const fileName = `${uniqueId}-${file.name}`;
  const path = `companies/${companyId}/projects/${projectId}/pdfs/${fileName}`;

  console.log("[Storage] Uploading PDF document:", {
    companyId,
    projectId,
    fileName,
    path,
  });

  return uploadFile(path, file);
}

export async function uploadChecklistImage(
  companyId: string,
  projectId: string,
  file: File,
): Promise<string> {
  if (!file.type.match("image/.*")) {
    throw new Error("File must be an image");
  }

  const uniqueId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  const fileName = `${uniqueId}-${file.name}`;
  const path = `companies/${companyId}/projects/${projectId}/checklistImages/${fileName}`;
  return uploadFile(path, file);
}

export async function uploadProjectImage(
  companyId: string,
  projectId: string,
  file: File,
): Promise<string> {
  if (!file.type.match("image/.*")) {
    throw new Error("File must be an image");
  }

  const uniqueId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  const fileName = `${uniqueId}-${file.name}`;
  const path = `companies/${companyId}/projects/${projectId}/images/${fileName}`;
  return uploadFile(path, file);
}

export async function uploadQualityCheckImage(
  companyId: string,
  projectId: string,
  serialNumber: string,
  file: File,
): Promise<string> {
  if (!file.type.match("image/.*")) {
    throw new Error("File must be an image");
  }

  const uniqueId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  const fileName = `${uniqueId}-${file.name}`;
  const path = `quality-checks/${companyId}/projects/${projectId}/serial-numbers/${serialNumber}/${fileName}`;
  return uploadFile(path, file);
}

export async function uploadTaskAttachment(
  companyId: string,
  taskId: string,
  file: File,
): Promise<string> {
  const uniqueId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  const fileName = `${uniqueId}-${file.name}`;
  const path = `tasks/${companyId}/tasks/${taskId}/attachments/${fileName}`;
  return uploadFile(path, file);
}
